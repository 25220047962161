import React, { lazy } from 'react';
const Login = lazy(() => import('../../screens/Auth/Login/Login'));

const appNav = [
  {
    name: 'Login',
    path: 'login',
    element: <Login />
  }
];

export default appNav;
