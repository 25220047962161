import React from 'react';
import { useRoutes } from 'react-router-dom';
import appRoutes from './appRoutes';
import authRoutes from './authRoutes';

const Router = () => {
  const element = useRoutes([
    ...appRoutes,
    ...authRoutes,
    { path: '*', element: <h1>Not Found</h1> }
  ]);

  return element;
};

Router.propTypes = {};

export default Router;
