import React, { lazy } from 'react';
import {
  SubNavMenuCompany,
  SubNavMenuProjects,
  SubNavMenuContracts,
  SubNavMenuTenders,
  SubNavMenuUser
} from './subNav';
import { Outlet } from 'react-router-dom';
const Home = lazy(() => import('../../screens/Main/Home/Home'));

const ArrayForNav = ['project', 'tenders', 'contract', 'company', 'manage-user'];

const getSubNavMenu = (path) => {
  switch (path) {
    case 'project':
      return SubNavMenuProjects;
    case 'tenders':
      return SubNavMenuTenders;
    case 'contract':
      return SubNavMenuContracts;
    case 'company':
      return SubNavMenuCompany;
    case 'manage-user':
      return SubNavMenuUser;
    default:
      return [];
  }
};

const superAdminNav = ArrayForNav?.map((path) => {
  return {
    path: path,
    element: <Outlet />,
    children: getSubNavMenu(path)
  };
});

export default superAdminNav;
