/**
 * Auth Reducer
 */

export const SET_AUTH = 'SET_AUTH';
export const SET_COMPANYDETAILS = 'SET_COMPANYDETAILS';
export const SET_NOTESDETAILS = 'SET_NOTESDETAILS';

export const SET_PROJECTDETAILS = 'SET_PROJECTDETAILS';
export const SET_PROJECTCOST = 'SET_PROJECTCOST';
export const SET_PROJECTOFFICIAL = 'SET_PROJECTOFFICIAL';

export const UPDATE_SUBMENU_COUNT = 'UPDATE_SUBMENU_COUNT';
