import { configureStore } from '@reduxjs/toolkit';

import AuthReducer from '../../library/common/reducers/AuthReducer';
import companyReducer from './companySlice';
import projectReducer from './projectSlice';
import updateSubMenuCountReducer from '../../library/common/reducers/updateSubMenuCountReducer';

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    company: companyReducer,
    project: projectReducer,
    update: updateSubMenuCountReducer
  }
});

export default store;
