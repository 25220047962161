import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { getTokenFromStorage } from "../../utils/common";
const baseUrl = process.env.REACT_APP_API_URL;

const initialState = {
    loading: false,
    data: [],
    error: '',
}


export const fetchCompanyDropDowns = createAsyncThunk('company/fetchDropDowns', async () => {
    const response = await axios.get(`${baseUrl}get-company-dropdowns`, {
        headers: {
            'Authorization': `Bearer ${getTokenFromStorage()}`,
        },
    });

    return response.data;
});

const companySlice = createSlice({
    name: 'company',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanyDropDowns.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchCompanyDropDowns.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
                state.error = '';
            })
            .addCase(fetchCompanyDropDowns.rejected, (state, action) => {
                state.loading = false;
                state.data = [];
                state.error = action.error.message || 'Failed to fetch data';
            });
    }
});

export default companySlice.reducer;
