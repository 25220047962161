import React, { lazy } from 'react';
import authNav from './authNav';
import Loader from '../../screens/Loader';
const RouteOutlet = lazy(() => import('./RouteOutlet'));
const Login = lazy(() => import('../../screens/Auth/Login/Login'));

const PrivateRoute = ({ as: Component, ...props }) => {
  const [componentLoaded, setComponentLoaded] = React.useState(true);
  // const navigate = useNavigate();
  React.useEffect(() => {
    // 	if (cookies.token) {
    //       navigate('/app/home');
    //       setComponentLoaded(false);
    //     } else if (window.location.pathname === '/') {
    //     navigate('/login');
    //     setComponentLoaded(false);
    //   } else {
    //       setComponentLoaded(true);
    //     }
    setComponentLoaded(true);
  }, []);
  return componentLoaded ? <Component {...props} /> : <h1>Loading...</h1>;
};

const authRoutes = [
  {
    path: 'auth',
    element: <PrivateRoute as={RouteOutlet} />,
    children: authNav
  },
  {
    path: '/',
    element: <Login />
  }
];

export default authRoutes;
