/* eslint-disable  */
import axios from 'axios';
// utilities
import { removeAuthUserFromStorage } from './common';
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const instance = axios.create({
  // baseURL: `/`,
  // withCredentials: true,
});

export const abortAllRequest = () => {
  source.cancel(); ///Operation canceled by the user.
};

// Adding a response interceptor
instance.interceptors.response.use(function (config) {
  // unauthorized - token expired
  if (config.data.status === 401) {
    // redirect to login page at this point
    removeAuthUserFromStorage();
    window.location.href = '/';
  }
  return config;
});

export default instance;
