/* eslint-disable react/react-in-jsx-scope */
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BusinessIcon from '@mui/icons-material/Business';
import ArticleIcon from '@mui/icons-material/Article';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import DraftsIcon from '@mui/icons-material/Drafts';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import DangerousIcon from '@mui/icons-material/Dangerous';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PublishIcon from '@mui/icons-material/Publish';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssessmentIcon from '@mui/icons-material/Assessment';

// Prefix Constants
export const APP_PREFIX = 'app';
export const APP_ROLE_PREFIX = {
  pt_admin: '/admin'
};

// Shared Inbox Routes
export const inboxRoutes = [
  { to: 'inbox', label: 'Inbox', redirect: 'inbox' },
  { to: 'draft', label: 'Draft', redirect: 'draft' },
  { to: 'inprocess', label: 'In Process', redirect: 'in_process' },
  { to: 'rejected', label: 'Rejected', redirect: 'rejected' },
  { to: 'pendingapproval', label: 'Pending Approval', redirect: 'pendingapproval' },
  { to: 'publish', label: 'Publish', redirect: 'publish' }
];

// Common Menu Items
const commonMenuItems = [
  { to: 'project', label: 'Project', icon: <AccountTreeIcon /> },
  { to: 'tenders', label: 'Tenders', icon: <NoteAltIcon /> },
  { to: 'contract', label: 'Contract', icon: <ArticleIcon /> },
  { to: 'company', label: 'Company', icon: <BusinessIcon /> },
  { to: 'manage-user', label: 'User Management', icon: <BusinessIcon /> }
];

// Role-based Menus
export const menus = {
  pt_admin: commonMenuItems,
  pt_researcher: commonMenuItems,
  pt_approver: commonMenuItems
};

// Shared SubMenu Items
const commonSubMenus = {
  company: [
    { to: 'Companies', label: 'Companies', icon: <FindInPageIcon /> },
    { to: 'MyCompanies', label: 'My Companies', icon: <AssessmentIcon /> },
    { to: 'Inbox', label: 'Inbox', icon: <EmailIcon /> }
  ],
  project: [
    { to: 'Projects', label: 'Projects', icon: <AssessmentIcon /> },
    { to: 'MyProjects', label: 'My Projects', icon: <FindInPageIcon /> },
    { to: 'Inbox', label: 'Inbox', icon: <EmailIcon /> }
  ],
  tenders: [
    { to: 'Projects', label: 'Projects', icon: <AssessmentIcon /> },
    { to: 'MyProject', label: 'My Project', icon: <FindInPageIcon /> }
  ],
  contract: [
    { to: 'Projects', label: 'Projects', icon: <AssessmentIcon /> },
    { to: 'MyProject', label: 'My Project', icon: <FindInPageIcon /> }
  ],
  "manage-user": [
    { to: 'User', label: 'User', icon: <AssessmentIcon />},
    { to: 'Role', label: 'Role', icon: <FindInPageIcon/> }
  ]
};

export const subMenus = {
  pt_admin: commonSubMenus,
  pt_researcher: commonSubMenus,
  pt_approver: commonSubMenus
};

// Shared SubMenu Icons
const commonSubMenuIcons = {
  company: [
    { to: 'Draft', label: 'Draft', icon: <DraftsIcon /> },
    { to: 'In Process', label: 'In Process', icon: <AccessTimeIcon /> },
    { to: 'Rejected', label: 'Rejected', icon: <DangerousIcon /> },
    { to: 'Pending Approval', label: 'Pending Approval', icon: <PendingActionsIcon /> },
    { to: 'Publish', label: 'Publish', icon: <PublishIcon /> }
  ],
  project: [
    { to: 'Draft', label: 'Draft', icon: <DraftsIcon /> },
    { to: 'Rejected', label: 'Rejected', icon: <DangerousIcon /> },
    { to: 'Pending Approval', label: 'Pending Approval', icon: <PendingActionsIcon /> },
    { to: 'Publish', label: 'Publish', icon: <PublishIcon /> },
    { to: 'New Project Task', label: 'New Project Task', icon: <PublishIcon /> }
  ]
};

export const subNavStatusListApi = {
  'company': 'get-status-list',
  'project': 'get-project-status-list'
}

export const subMenusIcon = {
  pt_admin: commonSubMenuIcons,
  pt_researcher: commonSubMenuIcons,
  pt_approver: commonSubMenuIcons
};
