export const removeAuthUserFromStorage = () => {
  window.localStorage.removeItem('authToken');
};

export const setTokenToStorage = (token) => {
  window.localStorage.setItem('authToken', token);
};

export const defaultSnackBarState = {
  show: false,
  type: 'success',
  message: '',
  vertical: '',
  horizontal: ''
};

export const getTokenFromStorage = () => {
  const token = window.localStorage.getItem('authToken');
  return token;
};

export const validationRegex = {
  pincode: /^[0-9][0-9\- ]{0,10}[0-9]$/,
  email:
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  mobile: /^(\+\d{1,3}[- ]?)?\d{10}$/,
  latitde: /([-+]?(([1-8]?\d(\.\d+))+|90))/,
  url: /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi,
  other: /^(?!\s|.*\s$)(?!\s*$).+/,
  description: /^(?!\s*$).+/
};

export const titlesData = [{ label: 'Mr.' }, { label: 'Mrs.' }, { label: 'Miss.' }];

export const formatAddress = (...args) => {
  const arr = [args];
  return arr.filter((e) => e).join(', ');
};

export default function removeBlankSpaces(obj) {
  function trimValues(value) {
    if (typeof value === 'string') {
      return value.trim();
    } else if (Array.isArray(value)) {
      return value.map(trimValues);
    } else if (value !== null && typeof value === 'object') {
      return removeBlankSpaces(value);
    }
    return value;
  }

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      obj[key] = trimValues(obj[key]);
    }
  }
  return obj;
}

// modal style
export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: '85%'
};

// close modal style
export const closeBoxStyle = {
  position: 'absolute',
  top: '-1px',
  left: '-50px',
  bgcolor: 'orange',
  width: 50,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '20px 0 0 20px',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)'
};
