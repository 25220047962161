import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#131628'
    },
    gray: {
      main: '#212B36'
    },
    white: {
      main: '#FFFFFF'
    },
    blue: {
      main: '#71b8ff'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#ffffff',
          color: 'black'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          variants: [
            {
              props: {
                variant: 'outlined'
              },
              style: {
                color: '#5f6f7f',
                whiteSpace: 'nowrap',
                paddingLeft: '2rem',
                paddingRight: '2rem',
                borderRadius: '8px',
                '&:hover': {
                  color: 'white',
                  backgroundColor: '#ffa534'
                },
                '&.MuiButton-outlined': { borderColor: '#ffa534' },
                '&.Mui-disabled': {
                  color: 'white',
                  backgroundColor: '#FFD7A8',
                  borderColor: 'white'
                }
              }
            },
            {
              props: {
                variant: 'filled'
              },
              style: {
                color: 'white',
                backgroundColor: '#ffa534',
                whiteSpace: 'nowrap',
                paddingLeft: '2rem',
                paddingRight: '2rem',
                borderRadius: '8px',
                '&:hover': {
                  color: 'white',
                  backgroundColor: '#ffa534'
                },
                '&.MuiButton-outlined': { borderColor: '#ffa534' },
                '&.Mui-disabled': {
                  color: 'white',
                  backgroundColor: '#FFD7A8',
                  borderColor: 'white'
                }
              }
            }
          ]
        }
      }
    }
  }
});

export default theme;
