import React, { lazy } from 'react';
import CompanyProvider from '../contexts/companyContext';
import { inboxRoutes } from '../../library/common/constants/navConstants';
import ProjectProvider from '../contexts/projectContext';

//company
const MyCompanies = lazy(() => import('../../screens/Main/Company/MyCompanies/MyCompanies'));
const Companies = lazy(() => import('../../screens/Main/Company/Companies/Companies'));
const InboxTable = lazy(() => import('../../screens/Main/Company/Inbox/InboxTable/InboxTable'));
const AddCompany = lazy(() => import('../../screens/Main/Company/Companies/AddCompany/AddCompany'));
const AddProject = lazy(() => import('../../screens/Main/Project/Projects/AddProject/AddProject'));

//project
const MyProject = lazy(() => import('../../screens/Main/Project/MyProject/MyProject'));
const Project = lazy(() => import('../../screens/Main/Project/Projects/Projects'));
const InboxTableProject = lazy(
  () => import('../../screens/Main/Project/Inbox/InboxTable/InboxTableProject')
);

//users
const Role = lazy(() => import('../../screens/Main/UserManagement/Role/RoleManagement'))
const User = lazy(() => import('../../screens/Main/UserManagement/User/UserManagement'))

//tenders
//contract

const SubNavMenuCompany = [
  {
    path: 'mycompanies',
    element: (
      <>
        <MyCompanies />
      </>
    )
  },
  {
    path: 'companies',
    element: (
      <>
        <Companies />
      </>
    )
  },
  {
    path: 'companies/add-company',
    element: (
      <CompanyProvider>
        <AddCompany />
      </CompanyProvider>
    )
  },
  {
    path: 'companies/edit-company-details/:companyID',
    element: (
      <CompanyProvider>
        <AddCompany />
      </CompanyProvider>
    )
  },
  ...inboxRoutes.map((route) => ({
    path: route?.to,
    element: <InboxTable />
  })),
  {
    path: 'newprojecttask',
    element: <div>I am new project task</div>
  }
];

const SubNavMenuProjects = [
  {
    path: 'myprojects',
    element: <MyProject />
  },
  {
    path: 'projects',
    element: <Project />
  },
  {
    path: 'projects/add-project',
    element: (
      <ProjectProvider>
        <AddProject />
      </ProjectProvider>
    )
  },
  {
    path: 'projects/edit-project-details/:projectID',
    element: (
      <ProjectProvider>
        <AddProject />
      </ProjectProvider>
    )
  },
  ...inboxRoutes.map((route) => ({
    path: route?.to,
    element: <InboxTableProject />
  }))
  // Add more project-related routes as needed
];

const SubNavMenuTenders = [
  {
    path: 'myproject',
    element: <MyProject />
  },
  {
    path: 'projects',
    element: (
      <>
        <Project />
      </>
    )
  }
  // Add more tenders-related routes as needed
];

const SubNavMenuContracts = [
  {
    path: 'myproject',
    element: <MyProject />
  },
  {
    path: 'projects',
    element: (
      <>
        <Project />
      </>
    )
  }
  // Add more contract-related routes as needed
];

const SubNavMenuUser = [
  {
    path: 'user',
    element: <User/>
  },
  {
    path: 'role',
    element: <Role />
  },
]

export { SubNavMenuCompany, SubNavMenuProjects, SubNavMenuTenders, SubNavMenuContracts, SubNavMenuUser };
