import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getTokenFromStorage } from '../../utils/common';
const baseUrl = process.env.REACT_APP_API_URL;

const initialState = {
  loading: false,
  data: [],
  error: ''
};

export const fetchProjectDropDowns = createAsyncThunk('project/fetchDropDowns', async () => {
  const response = await axios.get(`${baseUrl}get-projects-dropdowns`, {
    headers: {
      Authorization: `Bearer ${getTokenFromStorage()}`
    }
  });

  return response.data;
});

const projectSlice = createSlice({
  name: 'project',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectDropDowns.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProjectDropDowns.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        state.error = '';
      })
      .addCase(fetchProjectDropDowns.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.error.message || 'Failed to fetch data';
      });
  }
});

export default projectSlice.reducer;
