import { createContext, useContext, useReducer } from 'react';

import projectReducer, { initialState } from '../../library/common/reducers/projectContextReduce';

const ProjectContext = createContext();

export const useProjectContext = () => useContext(ProjectContext);

const ProjectProvider = ({ children }) => {
  const useProjectContextState = useReducer(projectReducer, initialState);

  return (
    <ProjectContext.Provider value={[...useProjectContextState]}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
