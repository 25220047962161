import { createContext, useContext, useReducer } from 'react';

import companyReducer, { initialState } from '../../library/common/reducers/companyContextReducer';
  
const CompanyContext = createContext();

export const useCompanyContext = () => useContext(CompanyContext);

const CompanyProvider = ({ children }) => {
    const useCompanyContextState = useReducer(
        companyReducer,
      initialState,
    );

    return (
      <CompanyContext.Provider value={[...useCompanyContextState]}>
        {children}
      </CompanyContext.Provider>
    );
  };

export default CompanyProvider;