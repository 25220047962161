import * as actionTypes from '../constants/StoreConstants';

const initialState = {
  isLoggedIn: false,
  user: null
};

const AuthReducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.SET_AUTH:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
        role: {
          isResearcher: action.payload?.roles?.name === 'Researcher',
          isApprover: action.payload?.roles?.name === 'Approver',
          isAdmin: action.payload?.roles?.name === 'Admin'
        }
      };
    default:
      return state;
  }
};

export default AuthReducer;
