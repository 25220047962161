
import { UPDATE_SUBMENU_COUNT } from '../constants/StoreConstants'

const initialState = {
    reload: false,
};

const updateSubMenuCountReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SUBMENU_COUNT:
            return {
                ...state,
                reload: action.payload.reload,
            };
        default:
            return state;
    }
};

export default updateSubMenuCountReducer;

