import React, { lazy } from 'react';
import superAdminNav from './superAdminNav';

const RouteOutlet = lazy(() => import('./RouteOutlet'));
const PrivateRoute = ({ as: Component, ...props }) => {
  const [auth, setAuth] = React.useState(true);
  // const navigate = useNavigate();
  // const cookies = {};p
  React.useEffect(() => {
    // if (!cookies.token) {
    //   navigate('../login');
    //   setAuth(false);
    // } else {
    //   setAuth(true);
    // }
    setAuth(true);
  }, []);
  return auth ? <Component {...props} /> : <h1>Loading...</h1>;
};

const appRoutes = [
  {
    path: 'app',
    element: <PrivateRoute as={RouteOutlet} />,
    children: superAdminNav
  }
];

export default appRoutes;
